import styled from "styled-components";

const StylPrivacy = styled.div`
  h3 {
    color: #19191b;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 4rem;
  }

  h3:first-child {
    margin-top: 0;
  }

  strong {
    color: #19191b;
    font-weight: 600;
  }

  p {
    margin-bottom: 1rem;
    color: rgba(25, 25, 27, 0.75);
    font-size: 18px;
    font-weight: 300;
  }

  ol {
    padding-left: 45px;
    margin-bottom: 1rem;
    li {
      color: rgba(25, 25, 27, 0.75);
      font-size: 18px;
      font-weight: 300;
    }
  }

  ul {
    list-style: outside;
    padding-left: 45px;
    margin-bottom: 1rem;
    li {
      color: rgba(25, 25, 27, 0.75);
      font-size: 18px;
      font-weight: 300;
    }
  }

  a:hover {
    text-decoration: underline !important;
  }
`;

export default StylPrivacy;
