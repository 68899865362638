import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import StylPrivacy from "../components/Styled/StylPrivacy";
import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List } from "../components/Core";
import styled from "styled-components";

const UserTerms = () => {
  return (
    <StylPrivacy>
      <h3>
        <strong>Acceptance of the Platform Terms and Conditions of Use</strong>
      </h3>
      <p>
        These website terms and conditions of use for https://rewardup.com/,
        constitute a legal agreement and are entered into by and between you and
        your successors and heirs (the &ldquo;<strong>User</strong>&rdquo;,
        &ldquo;<strong>you</strong>&rdquo;&nbsp;or&nbsp;&ldquo;
        <strong>your</strong>&rdquo;),and RewardUp Inc. ("
        <strong>Company</strong>," "<strong>we</strong>," "<strong>us</strong>,"
        "<strong>our</strong>"). The following terms and conditions, together
        with any documents and/or additional terms they expressly incorporate by
        reference (collectively, these "<strong>Terms and Conditions</strong>"),
        govern your access to and use, including any content, functionality, and
        services offered on or through{" "}
        <a href="https://rewardup.com/">https://rewardup.com/</a> (the "
        <strong>Website</strong>") or your use of any of the Company&rsquo;s
        services which are embedded by API into sites or software owned and
        maintained by third parties (&ldquo;<strong>Third</strong>
        <strong>Party Websites</strong>&rdquo;) as a widget (&ldquo;
        <strong>Widget</strong>&rdquo;). The Widget and the Website shall
        hereinafter collectively be referred to as the &ldquo;
        <strong>Platform</strong>&rdquo;. The services offered by the Company on
        the Platform shall hereinafter be referred to as &ldquo;
        <strong>Services</strong>&rdquo;.
      </p>
      <p>
        BY USING THE PLATFORM OR BY CLICKING TO ACCEPT THE TERMS AND CONDITIONS,
        YOU ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH THESE TERMS AND
        CONDITIONS AND OUR PRIVACY POLICY, FOUND AT HTTPS://REWARDUP.COM/
        INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS AND
        CONDITIONS OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THE
        PLATFORM.
      </p>
      <p>
        By using this Platform, you represent and warrant that you are the legal
        age of majority under applicable law to form a binding contract with the
        Company and meet all of the foregoing eligibility requirements. If you
        do not meet all of these requirements, you must not access or use the
        Platform.
      </p>
      <h3>
        <strong>Additional Terms</strong>
      </h3>
      <p>
        Your access to or use of the Widget on the Third-Party Websites, may
        also subject you to the additional terms, conditions, policies, rules,
        and guidelines, which govern your access to and use of the Platform on
        the Third-Party Websites (as applicable, the&nbsp;&ldquo;
        <strong>Additional Terms</strong>&rdquo;). By using our Services as a
        Widget in Third Party Websites, you agree to be bound by&nbsp;the
        Additional Terms and the Terms and Conditions. The Additional Terms will
        be posted (via a link) on the applicable Third-Party Platform and may be
        modified from time to time. To the extent there is a conflict between
        these Terms and any Additional Terms for a specific Third-Party
        Platform, the applicable Additional Terms shall govern and take
        precedence over these Terms with respect to your use of or access on
        that Third Party Website.
      </p>
      <p>
        The third-party retailers and brands who list their rewards on the
        Platform (<strong>&ldquo;Merchants&rdquo;</strong>) to whom you sign up
        may require additional terms, conditions, policies, rules, and
        guidelines, which govern your access to and use of the Merchant&rsquo;s
        services and goods. By accessing or using the rewards you collect
        through the Services at the Merchants, you agree to be bound
        by&nbsp;their Merchant Terms. The Merchant Terms will be posted either
        on its website or physically at its retail location and may be modified
        by the Merchant from time to time.&nbsp;
      </p>
      <h3>
        <strong>
          Modifications to the Terms and Conditions and to the Platform&nbsp;
        </strong>
      </h3>
      <p>
        We reserve the right in our sole discretion to revise and update these
        Terms and Conditions from time to time. Any and all such modifications
        are effective immediately upon posting and apply to all access to and
        continued use of the Platform. You agree to periodically review the
        terms and conditions in order to be aware of any such modifications and
        your continued use shall be your acceptance of these.&nbsp;
      </p>
      <p>
        The information and material on this Platform, and the Platform, may be
        changed, withdrawn, or terminated at any time in our sole discretion
        without notice. We will not be liable if, for any reason, all or any
        part of the Platform is restricted to users or unavailable at any time
        or for any period.
      </p>
      <h3>
        <strong>Electronic Communications</strong>
      </h3>
      <p>
        By accessing or using the Platform, you consent to receiving
        communications from us electronically. We will communicate with you in a
        variety of ways, including, without limitation, by e-mail, text, in-app
        push notices, or by posting notices and messages on the Platform. By
        accessing or using the Platform, you willingly and knowingly agree that
        all contracts, notices, disclosures, agreements, and other
        communications that we provide to you electronically satisfy any legal
        requirement that such communications be in writing.
      </p>
      <h3>
        <strong>
          Your Use of the Platform and Account Set-Up and Security
        </strong>
      </h3>
      <p>
        The security of your personal information is very important to us. We
        use physical, electronic, and administrative measures designed to secure
        your personal information from accidental loss and from unauthorized
        access, use, alteration, and disclosure.&nbsp;
      </p>
      <p>
        The safety and security of your information also depends on you. You are
        responsible for obtaining their own access to the Platform. You are
        required to ensure that all persons who access the Platform through a
        User's internet connection are aware of these Terms and Conditions and
        comply with them. The Platform, including content or areas of the
        Platform, may require User registration. It is a condition of your use
        of the Platform that all the information you provide on the Platform is
        correct, current, and complete.&nbsp;
      </p>
      <p>
        Unfortunately, the transmission of information via the Internet is not
        completely secure. Although we do our best to protect your personal
        information, we cannot guarantee the security of your personal
        information transmitted to our Platform. Any transmission of personal
        information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the Platform.
      </p>
      <p>
        Your provision of registration information and any submissions you make
        to the Platform through any functionality such as applications, account
        creation, and other such functions (collectively, "
        <strong>Interactive Functions</strong>") constitutes your consent to all
        actions we take with respect to such information consistent with our
        Privacy Policy, found at https://rewardup.com/.
      </p>
      <p>
        Any username, password, or any other piece of information chosen by you,
        or provided to you as part of our security procedures, must be treated
        as confidential, and you must not disclose it to any other person or
        entity. You must exercise caution when accessing your account from a
        public or shared computer so that others are not able to view or record
        your password or other personal information. You understand and agree
        that should you be provided an account, your account is personal to you
        and you agree not to provide any other person with access to this
        Platform or portions of it using your username, password, or other
        security information. You agree to notify us immediately of any
        unauthorized access to or use of your username or password or any other
        breach of security. You also agree to ensure that you logout from your
        account at the end of each session. You are responsible for any password
        misuse or any unauthorized access.&nbsp;
      </p>
      <p>
        We reserve the right at any time and from time to time, to disable or
        terminate your account, any username, password, or other identifier,
        whether chosen by you or provided by us, in our sole discretion for any
        or no reason, including any violation of any provision of these Terms
        and Conditions.
      </p>
      <p>
        You are prohibited from attempting to circumvent and from violating the
        security of this Platform, including, without limitation: (a) accessing
        content and data that is not intended for you; (b) attempting to breach
        or breaching the security and/or authentication measures which are not
        authorized; (c) restricting, disrupting or disabling service to users,
        hosts, servers, or networks; (d) illicitly reproducing TCP/IP packet
        header; (e) disrupting network services and otherwise disrupting
        Platform owner's ability to monitor the Platform; (f) using any robot,
        spider, or other automatic device, process, or means to access the
        Platform for any purpose, including monitoring or copying any of the
        material on the Platform; (g) introducing any viruses, trojan horses,
        worms, logic bombs, or other material that is malicious or
        technologically harmful; (h) attacking the Platform via a
        denial-of-service attack, distributed denial-of-service attack,
        flooding, mailbombing, or crashing; and (i) otherwise attempting to
        interfere with the proper working of the Platform.
      </p>
      <h3>
        <strong>Rewards</strong>
      </h3>
      <p>
        By using the Services, you may have the opportunity to qualify for
        rewards through promotional campaigns offered by Merchants that
        participate in the Services (the &ldquo;<strong>Rewards</strong>
        &rdquo;). All Rewards made available in connection with the Services are
        promotional only and have no cash value. You have no property value in
        any Rewards. Such Rewards are made available directly by the relevant
        Merchant providing such Reward, and not the Company, and are redeemable
        solely for the applicable goods or services of the relevant Merchant as
        may be determined by such Merchant. The Merchant, not the Company, is
        the provider of the Rewards and such goods and services and is solely
        responsible for redeeming any Rewards you obtain. The Company will have
        no liability if a Merchant refuses or fails to honor any Reward. In
        addition, the following terms and conditions also apply to all Rewards:
      </p>
      <ol>
        <li>
          Rewards may not be cashed out or transferred into any other medium of
          exchange.
        </li>
        <li>Rewards are non-transferable.</li>
        <li>Redemption frequency is determined by the Merchant.</li>
        <li>
          Use of Rewards relating to alcoholic beverages is at the sole
          discretion of the Merchant and is subject to compliance with
          applicable law.
        </li>
        <li>
          Rewards cannot be combined with any other rewards, offers, vouchers,
          third-party certificates, coupons, or promotions, unless otherwise
          specified by the Merchant.
        </li>
        <li>
          Neither The Company nor the Merchant is responsible for
          malfunctioning, lost, or stolen cards.
        </li>
        <li>Reproduction or sale of any Reward is prohibited.</li>
        <li>
          Any attempted redemption not consistent with these Terms and
          Conditions or any other restrictions imposed by the Merchant or the
          Company (including any Reward-specific terms associated with a Reward)
          will render the Reward void.
        </li>
        <li>Rewards are void to the extent prohibited by law.</li>
        <li>
          Rewards may be applied only to the applicable products or services
          sold by the Merchant that are the subject of such Rewards as
          determined by such Merchant.
        </li>
      </ol>
      <p>
        A Merchant may discontinue its Rewards program and participation with
        the Company&rsquo; Services at any time. The Company also reserves the
        right to terminate a Merchant&rsquo;s Rewards program and participation
        with the Services at any time. Upon termination of a Rewards Program for
        whatever reason, all Rewards will become null and void and will not be
        redeemable for any good, service, or any other value, financial or
        otherwise.
      </p>
      <h3>
        <strong>Intellectual Property Rights and Ownership&nbsp;</strong>
      </h3>
      <p>
        You understand and agree that the Platform and its entire contents,
        features, and functionality, including, but not limited to, all
        information, software, code, text, displays, graphics, photographs,
        video, audio, design, presentation, selection, and arrangement, are
        owned by the Company, its licensors, or other providers of such material
        and are protected in all forms by intellectual property laws including
        without limitation, copyright, trademark, patent, trade secret, and any
        other proprietary rights.&nbsp;
      </p>
      <p>
        The Company name, the Company logo, and all related names, logos,
        product and service names, designs, images, and slogans are trademarks
        of the Company or its affiliates or licensors. You must not use such
        marks without the prior written permission of the Company. Other names,
        logos, product and service names, designs, images, and slogans
        mentioned, or which appear on this Platform are the trademarks of their
        respective owners. Use of any such property, except as expressly
        authorized, shall constitute an infringement or violation of the rights
        of the property owner and may be a violation of federal or other laws
        and could subject the infringer to legal action.
      </p>
      <p>
        You shall not directly or indirectly reproduce, compile for an internal
        database, distribute, modify, create derivative works of, publicly
        display, publicly perform, republish, download, store, or transmit any
        of the material on our Platform, in any form or medium whatsoever
        except:&nbsp;
      </p>
      <ol>
        <li>
          your computer and browser may temporarily store or cache copies of
          materials being accessed and viewed; and
        </li>
        <li>
          a reasonable number of copies for personal use only may be printed
          keeping any proprietary notices thereon, which may only be used for
          non-commercial and lawful personal use and not for further
          reproduction, publication, or distribution of any kind on any medium
          whatsoever.
        </li>
      </ol>
      <p>
        Users are notpermitted to modify copies of any materials from this
        Platform nor delete or alter any copyright, trademark, or other
        proprietary rights notices from copies of materials from this Website.
        You must not access or use for any commercial purposes any part of the
        Platform or any Services or materials available through the Platform.
      </p>
      <p>
        You have no right, title, or interest in or to the Platform or to any
        content on the Platform, and all rights not expressly granted are
        reserved by the Company. Any use of the Platform not expressly permitted
        by these Terms and Conditions is a breach of these Terms and Conditions
        and may infringe or violate copyright, trademark, and other intellectual
        property or other proprietary laws.
      </p>
      <h3>
        <strong>Conditions of Use&nbsp;</strong>
      </h3>
      <p>
        As a condition of your access and use, you agree that you may use the
        Platform only for lawful purposes and in accordance with these Terms and
        Conditions.&nbsp;
      </p>
      <p>
        Without limiting the foregoing, you warrant and agree that your use of
        the Platform shall not:
      </p>
      <ol>
        <li>
          In any manner violate any applicable federal, provincial, local, or
          international law or regulation including, without limitation, any
          laws regarding the export of data or software, patent, trademark,
          trade secret, copyright, or other intellectual property, legal rights
          (including the rights of publicity and privacy of others) or contain
          any material that could give rise to any civil or criminal liability
          under applicable laws or regulations or that otherwise may be in
          conflict with these Terms and Conditions and our Privacy Policy.
        </li>
        <li>In any manner violate the Additional Terms.</li>
        <li>
          Involve, provide, or contribute any false, inaccurate, or misleading
          information.&nbsp;
        </li>
        <li>
          Impersonate or attempt to impersonate the Company, a Company employee,
          another user, or any other person or entity (including, without
          limitation, by using email addresses, or screen names associated with
          any of the foregoing).
        </li>
        <li>
          Transmit, or procure the sending of, any advertisements or promotions
          without our prior written consent, sales, or encourage any other
          commercial activities, including, without limitation, any "spam",
          "junk mail", "chain letter", contests, sweepstakes and other sales
          promotions, barter, or advertising or any other similar
          solicitation.&nbsp;
        </li>
        <li>
          Encourage any other conduct that restricts or inhibits anyone's use or
          enjoyment of the Platform, or which, as determined by us, may harm the
          Company or users of the Platform or expose them to liability.
        </li>
        <li>
          Cause annoyance, inconvenience, or needless anxiety or be likely to
          upset, embarrass, or alarm any other person.
        </li>
        <li>
          Promote any illegal activity, or advocate, promote, or assist any
          unlawful act.
        </li>
        <li>
          Give the impression that they originate from or are endorsed by us or
          any other person or entity, if this is not the case.
        </li>
      </ol>
      <h3>
        <strong>Errors and Omissions&nbsp;</strong>
      </h3>
      <p>
        Please note that the Platform may contain typographical errors or
        inaccuracies and may not be complete or current. We reserve the right to
        correct any errors, inaccuracies or omissions and to change or update
        information at any time, without prior notice (including after an order
        has been submitted). Such errors, inaccuracies or omissions may relate
        to product description, pricing, promotion and availability and we
        reserve the right to cancel or refuse any order placed based on
        incorrect pricing or availability information, to the extent permitted
        by applicable law. We do not undertake to update, modify or clarify
        information on our website, except as required by law.
      </p>
      <h3>
        <strong>No Reliance&nbsp;</strong>
      </h3>
      <p>
        The content on our Platform is provided for general information purposes
        only. It is not intended to amount to advice on which you should rely.
        You must obtain more specific or professional advice before taking, or
        refraining from, any action or inaction on the basis of the content on
        our site.
      </p>
      <p>
        Although we make reasonable efforts to update the information on our
        Platform, we make no representations, warranties, or guarantees, whether
        express or implied, that the content on our Platform is accurate,
        complete, or up to date. Your use of the Platform is at your own risk
        and neither the Company nor its subsidiaries, affiliates, and their
        respective directors, officers, employees, agents, service providers,
        contractors, licensors, licensees, suppliers, or successors have any
        responsibility or liability whatsoever for your use of this Platform.
      </p>
      <p>
        This Platform may include content provided by third parties, including
        from other users and third-party licensors. All statements and/or
        opinions expressed in any such third-party content, other than the
        content provided by the Company, are solely the opinions and the
        responsibility of the person or entity providing those materials. Such
        materials do not necessarily reflect the opinion of the Company. Neither
        the Company nor its subsidiaries, affiliates, and their respective
        directors, officers, employees, agents, service providers, contractors,
        licensors, licensees, suppliers, or successors have any responsibility
        or liability whatsoever to you, or any third party, for the content or
        accuracy of any third-party materials.
      </p>
      <h3>
        <strong>Privacy&nbsp;</strong>
      </h3>
      <p>
        By submitting your personal information and using our Platform, you
        consent to the collection, use, reproduction, hosting, transmission, and
        disclosure of any such user content submissions in compliance with our
        Privacy Policy, found at https://rewardup.com/ as we deem necessary for
        use of the Platform and provision of Services.&nbsp;
      </p>
      <p>
        By using this Platform you are consenting to the use of cookies which
        allow a server to recall previous requests or registration and/or IP
        addresses to analyze website use patterns. You can set your browser to
        notify you before you receive a cookie, giving you the chance to decide
        whether to accept it. You can also set your browser to turn off cookies.
        If you do, however, some areas of the Platform may not function
        adequately. For more information on this automated information gathering
        practices, see our Privacy Policy, found at support@rewardup.com.&nbsp;
      </p>
      <h3>
        <strong>Third-Party Websites&nbsp;</strong>
      </h3>
      <p>
        For your convenience, this Platform may provide links or pointers to
        third-party sites such as Merchants. We make no representations about
        any such websites that may be accessed from this Platform. If you choose
        to access any such sites, you do so at your own risk. We have no control
        over the contents of any such third-party sites and accept no
        responsibility for such sites or for any loss or damage that may arise
        from your use of them.&nbsp;
      </p>
      <h3>
        <strong>Geographic Restrictions</strong>
      </h3>
      <p>
        The owner of the Platform is based in Ontario in Canada. This Platform
        is not intended for use in any jurisdiction where its use is not
        permitted. If you access the Platform from outside Canada, you do so at
        your own risk and you are responsible for compliance with local laws of
        your jurisdiction.
      </p>
      <h3>
        <strong>Disclaimer of Warranties</strong>
      </h3>
      <p>
        The Merchants are solely responsible for ensuring that their products or
        services are offered in a safe, legal, and compliant manner. REWARDUP
        has no responsibility or liability for any products or services offered
        by any Merchant. Nor does REWARDUP have any responsibility or liability
        for any reviews or statements posted on the Platform regarding any
        products or services offered by any Merchant.
      </p>
      <p>
        You expressly agree and acknowledge that REWARDUP (i) is a loyalty and
        marketing platform for businesses; (ii) is not a restaurant, retailer,
        manufacturer, wholesaler or distributor, point of sale system, seller,
        or brand; (iii) does not itself, provide sales, pickup services,
        distribution or delivery of any retail or food product, nor does
        REWARDUP itself process payments; (iv) does not itself prepare,
        manufacture, package, label, test, or sell any food or retail product;
        and (v) does not independently verify the credentials or representations
        of any of the Merchants, the authenticity or quality of any of their
        products or services, or any Merchant&rsquo;s compliance with any
        applicable laws.
      </p>
      <p>
        You must make yourself comfortable through the information provided by
        the Merchants by contacting the Merchants directly as to the quality and
        reliability of the Merchants and the Merchants&rsquo; compliance with
        applicable laws. REWARDUP is not responsible for guaranteeing the
        quality of any Merchant&rsquo;s food or retail product, or any
        Merchant&rsquo;s compliance with applicable law. In addition, a Merchant
        may represent certain reward, bonuses, gifts, loyalty credits
        (collectively, &ldquo;<strong>Points</strong>&rdquo;) will equate to a
        certain cash value, and while this information may be used by REWARDUP
        to provide its services, REWARDUP does not investigate or verify any
        such statements and will not provide cash to you in exchange for any
        Points you have accrued with any Merchant.&nbsp;
      </p>
      <p>
        The Platform might display, include, or make available data,
        information, links, articles, applications or other products, services,
        and/or materials provided to us by Merchants (&ldquo;Merchant
        Material&rdquo;) or contain links to third-party websites, services, and
        advertisements for Merchant Offers (as defined below) (collectively,
        the&nbsp;&ldquo;<strong>Merchant Materials</strong>&rdquo;). You
        acknowledge and agree that REWARDUP is not responsible for Merchant
        Materials, including their accuracy, completeness, timeliness, validity,
        copyright compliance, legality, decency, quality, or any other aspect
        thereof. REWARDUP does not assume and will not have any liability or
        responsibility to you or any other person or user for any Merchant
        Materials. Merchant Materials are provided solely as a convenience to
        you and you access and use them entirely at your own risk. When you link
        to Merchant Materials, the applicable third party&rsquo;s terms and
        policies apply, including the third party&rsquo;s privacy and data
        gathering practices. You should make whatever investigation you feel
        necessary or appropriate before proceeding with any transaction in
        connection with such Merchant Materials.&nbsp;
      </p>
      <p>
        The Platform might display, include, or make available coupons,
        promotional codes, giveaways, samples, and other offers from listed
        dispensaries or other third parties (collectively, the&nbsp;
        <strong>&ldquo;Merchant Offers&rdquo;</strong>). REWARDUP displays these{" "}
        <strong>Merchant </strong>Offers on the Websites and Services as a form
        of advertisement for the Merchant only. All Merchant Offers are made
        directly by the applicable Merchant, and may be subject to additional
        terms, conditions, or restrictions of the Merchant or under applicable
        law, whether or not such terms, conditions, or restrictions are
        expressly included on the Platform. The Merchant, and not REWARDUP, is
        solely responsible for: (i) redemption of the Merchant Offer; (ii)
        compliance of all aspects of the Merchant Offer with applicable law
        (including, without limitation, the advertisement, redemption, and
        terms, conditions, and restrictions related thereto); (iii) all goods
        and services it provides to you in connection with the Merchant Offer;
        and (iv) all injuries, illnesses, damages, claims, liabilities, and
        costs it may cause you to suffer, directly or indirectly, in full or in
        part, whether or not related to the use or redemption of a Merchant
        Offer.
      </p>
      <p>
        YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE PLATFORM, ITS CONTENT, AND
        ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE PLATFORM IS AT YOUR
        OWN RISK. THE PLATFORM, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR
        ATTAINED THROUGH THE PLATFORM ARE PROVIDED ON AN "AS IS" AND "AS
        AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND,
        EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
        NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT
        CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </p>
      <p>
        NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR
        RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
        CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY
        WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE
        COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR
        AVAILABILITY OF THE PLATFORM OR ITS CONTENTS. WITHOUT LIMITING THE
        FOREGOING, NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES
        OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
        PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS
        REPRESENT OR WARRANT THAT THE PLATFORM, ITS CONTENT, OR ANY SERVICES OR
        ITEMS FOUND OR ATTAINED THROUGH THE PLATFORM WILL BE ACCURATE, RELIABLE,
        ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR
        PLATFORM OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
        OTHER HARMFUL COMPONENTS.
      </p>
      <p>
        WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE
        FOR DOWNLOADING FROM THE INTERNET OR THE PLATFORM WILL BE FREE OF
        VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY
        RESPONSIBLE FOR YOUR USE OF THE PLATFORM AND YOUR COMPUTER, INTERNET,
        AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE
        LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK,
        DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING,
        MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR
        OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
        EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO
        YOUR USE OF THE PLATFORM OR ANY SERVICES OR ITEMS FOUND OR ATTAINED
        THROUGH THE PLATFORM OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON
        IT, OR ON ANY PLATFORM LINKED TO IT.
      </p>
      <h3>
        <strong>Limitation on Liability</strong>
      </h3>
      <p>
        EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO
        CIRCUMSTANCE WILL THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES
        OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
        PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS
        BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION,
        FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
        INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
        PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN
        AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
        LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
        LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
        OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS
        ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION
        WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE PLATFORM, ANY
        LINKED PLATFORMS OR SUCH OTHER THIRD-PARTY PLATFORMS, NOR ANY PLATFORM
        CONTENT, MATERIALS, POSTING, OR INFORMATION THEREON EVEN IF THE PARTY
        WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW.
      </p>
      <h3>
        <strong>Indemnification</strong>
      </h3>
      <p>
        To the maximum extent permitted by applicable law, you agree to defend,
        indemnify, and hold harmless the Company, its parent, subsidiaries,
        affiliates, and their respective directors, officers, employees, agents,
        service providers, contractors, licensors, suppliers, successors, and
        assigns from and against any claims, liabilities, damages, judgments,
        awards, losses, costs, expenses, or fees (including reasonable
        attorneys' fees) arising out of or relating to your breach of these
        Terms and Conditions or your use of the Platform, including, but not
        limited to, your User Submissions, third-party sites, any use of the
        Platform's content, Services, and products other than as expressly
        authorized in these Terms and Conditions.
      </p>
      <h3>
        <strong>Governing Law and Choice of Forum</strong>
      </h3>
      <p>
        The Platform and these Terms and Conditions will be governed by and
        construed in accordance with the laws of the Province of Ontario and the
        federal laws of Canada applicable therein, without giving effect to any
        choice or conflict of law provision, principle, or rule (whether of the
        laws of the Province of Ontario or any other jurisdiction) and
        notwithstanding your domicile, residence, or physical location.
      </p>
      <p>
        Any action or proceeding arising out of or relating to this Platform and
        under these Terms and Conditions will be instituted in the courts of the
        Province of Ontario and/or the Federal Court of Canada, and each party
        irrevocably submits to the exclusive jurisdiction of such courts in any
        such action or proceeding. You waive any and all objections to the
        exercise of jurisdiction over you by such courts and to the venue of
        such courts.
      </p>
      <h3>
        <strong>Waiver&nbsp;</strong>
      </h3>
      <p>
        No failure to exercise, or delay in exercising, any right, remedy,
        power, or privilege arising from these Terms and Conditions operates, or
        may be construed, as a waiver thereof. No single or partial exercise of
        any right, remedy, power, or privilege hereunder precludes any other or
        further exercise thereof or the exercise of any other right, remedy,
        power, or privilege.
      </p>
      <h3>
        <strong>Severability</strong>
      </h3>
      <p>
        If any term or provision of these Terms and Conditions is invalid,
        illegal, or unenforceable in any jurisdiction, such invalidity,
        illegality, or unenforceability shall not affect any other term or
        provision of these Terms and Conditions or invalidate or render
        unenforceable such term or provision in any other jurisdiction.
      </p>
      <h3>
        <strong>Entire Agreement</strong>
      </h3>
      <p>
        The Terms and Conditions and the Privacy Policy and constitute the sole
        and entire agreement between you and the Company regarding the Platform
        and supersedes all prior and contemporaneous understandings, agreements,
        representations and warranties, both written and oral, regarding such
        subject matter.&nbsp;
      </p>
      <h3>
        <strong>Reporting and Contact</strong>
      </h3>
      <p>
        This website is operated by RewardUp Inc. Should you become aware of
        misuse of the website including libelous or defamatory conduct, you must
        report it to the Company at support@rewardup.com.
      </p>
      <p>
        All other feedback, comments, requests for technical support, and other
        communications relating to the Platform should be directed to
        support@rewardup.com.
      </p>
      <p>Date: August 09, 2021</p>
    </StylPrivacy>
  );
};

const MerchantTerms = () => {
  return (
    <StylPrivacy>
      <p>Last revised: August 9, 2021</p>
      <p>
        These Terms of Service for Merchants is entered into by and between you
        and RewardUp Inc. ("<strong>Company</strong>," "<strong>we</strong>," "
        <strong>us</strong>," "<strong>our</strong>"). The following terms and
        conditions, together with any documents and/or additional terms they
        expressly incorporate by reference (collectively, this "
        <strong>Agreement</strong>"), governs your access to and use, including
        any content, functionality, and services offered on or through the
        Websites.&nbsp;
      </p>
      <p>
        PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. THESE TERMS AND
        CONDITIONS OF USE MAY HAVE CHANGED SINCE YOUR LAST VISIT TO THIS WEBSITE
        AND TO THE SERVICES. YOU AGREE TO CHECK FOR UPDATES TO THESE TERMS AND
        CONDITIONS OF USE. BY USING THIS WEBSITE OR REWARDUP&rsquo;S SERVICES,
        YOU INDICATE YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS OF USE. IF
        YOU DO NOT ACCEPT THESE TERMS AND CONDITIONS OF USE, THEN YOU MAY NOT
        USE THIS WEBSITE OR THE SERVICES.
      </p>
      <p>
        By checking the box or clicking the button next to a link to these terms
        on any of our sign-up pages, by signing a Registration Form as a you on
        the Websites, by logging in to your REWARDUP account, by accessing the
        Websites or by accessing any of the Services (including by means of any
        API interface), you accept this Agreement on behalf of yourself and any
        business or organization you represent. Any terms and conditions that
        may be contained in any acknowledgement, invoice, purchase order or
        other form you provide are specifically null and void.
      </p>
      <p>
        IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A CORPORATION OR
        OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND
        SUCH ENTITY TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERMS
        &ldquo;YOU&rdquo; OR &ldquo;YOUR&rdquo; SHALL REFER TO SUCH ENTITY. IF
        YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS
        AND CONDITIONS, YOU MAY NOT USE THE SERVICES. As part of
        REWARDUP&rsquo;s service, including offline components, REWARDUP will
        provide you with use of the Services for the duration of the Term. For
        reference, a Definitions section is included at the end of these Terms
        of Service.
      </p>
      <ol>
        <li>
          <div>
            <strong>Privacy, Security, and Disclosure</strong>
            <p>
              Please also review our Privacy Policy (the &ldquo;Privacy{" "}
              <strong>Policy</strong>&rdquo;), which governs REWARDUP&rsquo;S
              collection and use of your personal information. You understand
              that through your access or use of our Website and any of our
              Services (defined below), you consent to the collection and use
              (as set forth in the Privacy Policy) of this information,
              including the transfer of information to the United States and/or
              other countries for storage, processing, and use by RewardUp.
            </p>
          </div>
        </li>
        <li>
          <div>
            <strong>License Grant &amp; Restrictions</strong>
            <p>
              REWARDUP hereby grants you a non-exclusive, non-transferable
              (other than in connection with a permitted assignment pursuant to
              Section 18) worldwide right to access and use the Services, solely
              for your own lawful business purposes in accordance with the terms
              and conditions of the Agreement. All rights not expressly granted
              to you in this Section 2 are reserved by REWARDUP and its
              Affiliates. You may not access the Services if you or any of your
              Affiliates is/are a direct competitor of REWARDUP, except with
              REWARDUP&rsquo;s prior written consent, which may be refused in
              its absolute discretion. In addition, you may not access the
              Services for purposes of monitoring its availability, performance
              or functionality, or for any other benchmarking or competitive
              purposes. You shall not, except as expressly permitted in the
              Agreement, (i) license, sublicense, sell, resell, transfer,
              assign, distribute or otherwise commercially exploit or make
              available to any third party, whether for remuneration or not, our
              Intellectual Property Rights, our Services or the Content in any
              way (ii) modify or make derivative works based upon the Services
              or the Content; (iii) create external internet &ldquo;links&rdquo;
              to the Services or &ldquo;mirror&rdquo; any Content on any other
              server or wireless or Internet-based device; or (iv) reverse
              engineer or access the Services in order to (a) build a
              competitive product or service, (b) build a product using similar
              ideas, features, functions or graphics of the Services, and/or (c)
              copy any ideas, features, functions or graphics of the Services.
              You may use the Services only for your own lawful business
              purposes and, without limitation, shall not: (i) send spam or
              otherwise duplicative or unsolicited messages in violation of
              applicable laws; (ii) send or store infringing, obscene,
              threatening, libelous or otherwise unlawful or tortious material,
              including, without limitation, material harmful to children or
              violative of third party privacy rights; (iii) knowingly send or
              store material containing software viruses, worms, Trojan horses
              or other harmful computer code, files, scripts, agents or
              programs; (iv) knowingly interfere with or disrupt the integrity
              or performance of the Services or the software contained therein;
              (v) attempt to gain unauthorized access to the Services or its
              related systems or networks; or (vi) use the Services as a service
              bureau or allow the use of the Services or the software contained
              therein by or on behalf of third parties, except as specifically
              permitted in the Agreement.
            </p>
            <p>
              You grant to REWARDUP a non-exclusive, worldwide license and right
              to display, reproduce and use your trademarks and logos
              (collectively, &ldquo;<strong>You Marks</strong>&rdquo;) and
              business description solely in connection with the operation of
              the Websites. As part of the Websites, you will create rewards
              programs that you will offer to your customers through the
              Websites (&ldquo;<strong>Rewards Program</strong>
              &rdquo;). You represent, warrant and covenant that you will honor
              any Rewards Programs you offer, even after any expiration or
              termination of this Agreement. You further represent, warrant and
              covenant that Rewards Programs will comply with all applicable
              laws and regulations. You agree to honor your REWARDUP rewards and
              promotions and can adjust them by notifying REWARDUP in writing
              at&nbsp;
              <a href="mailto:support@rewardup.com">support@rewardup.com</a>.
              You agree to receive periodic SMS promotions from REWARDUP during
              use of the Services. You can opt out at any time by contacting us.
              Following any expiration or termination of this Agreement and if
              requested by you within thirty (30) days of such expiration or
              termination, REWARDUP will provide you with any relevant
              information in REWARDUP&rsquo; possession regarding the
              then-current status of your customers in relation to your Rewards
              Program.
              <br />
              <br />
            </p>
          </div>
        </li>
        <li>
          <div>
            <strong>Your Responsibilities</strong>
            <p>You shall:&nbsp;</p>
            <ol style={{ listStyle: "lower-roman" }}>
              <li>
                promptly notify REWARDUP promptly of any unauthorized use of any
                password or account or any other known or suspected breach of
                security;&nbsp;
              </li>
              <li>
                report to REWARDUP promptly and use reasonable efforts to stop
                immediately any copying or distribution of Content that is known
                or suspected by you; and&nbsp;
              </li>
              <li>
                not impersonate another REWARDUP user or provide false identity
                information to gain access to or use the Services.&nbsp;
              </li>
            </ol>
            <p>
              You are responsible for ensuring that your use of the Websites and
              the Services do not generate a number of spam or other complaints
              in excess of industry norms. RewardUp may terminate your access to
              or use of the Websites and the Services if we determine that your
              level of spam or other complaints is higher than industry norms,
              as determined by us in our sole discretion. As a matter of
              privacy, we will not share with you, information about those
              recipients who complain about your use of the Services or file a
              spam report against you.
            </p>
            <p>
              You are responsible for all activity occurring under your user
              accounts and shall abide by all applicable local, provincial,
              state, national and foreign laws, treaties and regulations in
              connection with your use of the Services, including:
            </p>
            <ol style={{ listStyle: "lower-roman" }}>
              <li>
                those related to data privacy, international communications and
                the transmission of technical or personal data;
              </li>
              <li>the CAN-SPAM Act;</li>
              <li>Canada's Anti-Spam Legislation;</li>
              <li>
                any policies and laws related to unsolicited SMS (TEXT)
                Messages, emails, spamming, privacy, copyright and trademark
                infringement;
              </li>
              <li>
                laws relating to advertising, sales or promotional efforts or
                practices, redemption, refunds and provision of your Services or
                services;
              </li>
              <li>
                laws that govern false, unfair and deceptive practices, coupons,
                gift cards/certificates, defective Services or services,
                unclaimed property, alcohol or tobacco, health and safety, fire,
                and hygiene standards;
              </li>
              <li>
                laws that govern lotteries, sweepstakes, contests and
                promotions; and
              </li>
              <li>
                laws that govern the collection of donations and charitable
                giving.
              </li>
            </ol>
            <p>
              You are responsible for determining whether our Websites or
              Services are suitable for you to use in light of any regulations,
              such as the Health Insurance Portability and Accountability Act ("
              <strong>HIPAA</strong>"), European data privacy laws, or other
              laws. If you are subject to a particular regulation and you use
              our Websites or Services, then RewardUp will not be liable if our
              Websites or Services do not meet those requirements.
            </p>
          </div>
        </li>
        <li>
          <div>
            <strong>Intellectual Property</strong>
            <p>
              You understand and agree that the Websites and its entire
              contents, features, and functionality, including, but not limited
              to, all information, software, code, text, displays, graphics,
              photographs, video, audio, design, presentation, selection, and
              arrangement, are owned by RewardUp, its licensors, or other
              providers of such material and are protected in all forms by
              intellectual property laws including without limitation,
              copyright, trademark, patent, trade secret, and any other
              proprietary rights.&nbsp;
            </p>
            <p>
              RewardUp&rsquo;s name, RewardUp&rsquo;s logo, and all related
              names, logos, product and service names, designs, images, and
              slogans are trademarks of RewardUp or its affiliates or licensors.
              You must not use such marks without the prior written permission
              of RewardUp. Other names, logos, product and service names,
              designs, images, and slogans mentioned, or which appear on this
              Website are the trademarks of their respective owners. Use of any
              such property, except as expressly authorized, shall constitute an
              infringement or violation of the rights of the property owner and
              may be a violation of federal or other laws and could subject the
              infringer to legal action.
            </p>
            <p>
              You may only use the Website for your personal and non-commercial
              use. You shall not directly or indirectly reproduce, compile for
              an internal database, distribute, modify, create derivative works
              of, publicly display, publicly perform, republish, download,
              store, or transmit any of the material on our Website, in any form
              or medium whatsoever except:&nbsp;
            </p>
            <p>
              (a)your computer and browser may temporarily store or cache copies
              of materials being accessed and viewed; and
            </p>
            <p>
              (b)a reasonable number of copies for personal use only may be
              printed keeping any proprietary notices thereon, which may only be
              used for non-commercial and lawful personal use and not for
              further reproduction, publication, or distribution of any kind on
              any medium whatsoever.
            </p>
            <p>
              Users are not permitted to modify copies of any materials from
              this Website nor delete or alter any copyright, trademark, or
              other proprietary rights notices from copies of materials from
              this site. You must not access or use for any commercial purposes
              any part of the Website or any services or materials available
              through the Website.
            </p>
            <p>
              If you print off, copy or download any part of our Website in
              breach of this Agreement, your right to use the Website will cease
              immediately and you must, at our option, return or destroy any
              copies of the materials you have made. You have no right, title,
              or interest in or to the Website or to any content on the Website,
              and all rights not expressly granted are reserved by RewardUp. Any
              use of the Website not expressly permitted by this Agreement is a
              breach of this Agreement and may infringe or violate copyright,
              trademark, and other intellectual property or other proprietary
              laws.
            </p>
          </div>
        </li>
        <li>
          <div>
            <strong>Third Party Service Providers</strong>
            <p>
              During use of the Services, you may interact with, or purchase or
              subscribe to goods and/or services from, a variety of third party
              providers, including but not limited to the following (hereinafter
              collectively referred to as &ldquo;
              <strong>Third Party Service Providers</strong>&rdquo;):
            </p>
            <ol style={{ listStyle: "lower-roman" }}>
              <li>
                third party applications such as email, SMS/TEXT, Point of Sale
                (&ldquo;<strong>POS</strong>&rdquo;), social media, BI, and
                other third party systems that interoperate with our systems to
                provide you with the Services; and
              </li>
              <li>
                other third party providers of ancillary software, hardware or
                services you interact with, or purchase or subscribe to in the
                process of using our Services.
              </li>
            </ol>
            <p>
              REWARDUP does not endorse any Third Party Service Providers. Any
              such activity, and any terms, conditions, warranties or
              representations associated with such activity are solely between
              you and the applicable Third Party Service Providers. REWARDUP and
              its Affiliates shall have no liability, obligation or
              responsibility for any relationship between you and any such Third
              Party Service Provider. You recognize, that certain Third Party
              Service Providers may require your agreement to additional or
              different license or other terms prior to your use of or access to
              such software, hardware or services.
            </p>
            <p>
              Our Services may depend on the continuing availability of Third
              Party Service Providers&rsquo; respective application programming
              interfaces (&ldquo;<strong>API</strong>&rdquo;). If any of these
              respective parties ceases to make its respective API or program
              available on reasonable terms for the Services, REWARDUP may cease
              providing such Service features without entitling you to any
              refund, credit or other compensation. REWARDUP provides API only
              as a matter of integration needs, and in no event shall REWARDUP
              be responsible for any issue relating to integration with Third
              Party Service Providers.&nbsp;
            </p>
          </div>
        </li>
        <li>
          <strong>Charges and Payment of Fees</strong>
          <div>
            <p>
              You shall pay all fees or charges to your account in accordance
              with the fee schedule set forth in the Registration Form. You are
              responsible for paying for the Services ordered for the entire
              Term, whether or not the Services are actively used unless non-use
              is based on continuous unavailability of the Services. Added
              services based on the increase in the level of services will be
              subject to additional fees agreed upon between you and REWARDUP
              and reflected in a written Registration Form. Services added in
              the middle of a billing month will be pro-rated for that billing
              month. All pricing terms are confidential, and you agree not to
              disclose them to any third party other than to your attorneys and
              advisors.
            </p>
            <p>
              If your business&rsquo; registered office is located in Canada,
              all sums&nbsp;of money which are referred to in the Registration
              Form and in this Agreement shall be charged in lawful money of
              Canada, unless otherwise specified.
            </p>
            <p>
              If your business&rsquo; registered office is in the United States
              of America, all sums&nbsp;of money which are referred to in the
              Registration Form and in this Agreement shall be charged in United
              States Dollars, unless otherwise specified.
            </p>
          </div>
        </li>
        <li>
          <strong>Billing; Renewal and Other Fees</strong>
          <div>
            <p>
              REWARDUP charges and collects in advance for use of the Services.
              REWARDUP will invoice you monthly or as otherwise mutually agreed
              upon in writing. The renewal charge for each billing month shall
              be the same as that during the prior month unless we have given
              you written notice of a pricing increase at least 30 days before
              the end of such month, in which case the pricing increase shall be
              effective upon renewal and thereafter.&nbsp;
            </p>
            <p>
              REWARDUP&rsquo;s fees are exclusive of all taxes, levies, or
              duties imposed by taxing authorities, and you shall be responsible
              for payment of all such taxes, levies, or duties, excluding only
              taxes based solely on REWARDUP&rsquo;s income. You agree to
              provide REWARDUP with complete and accurate billing and contact
              information. This information includes your legal company name,
              street address, e-mail address, and name of an authorized billing
              contact. You agree to update this information within 30 days of
              any change to it. If you believe your bill is incorrect, you must
              contact us in writing. Any dispute regarding an invoice must be
              sent to REWARDUP within 30 days of the date of the invoice, and
              shall include the amount in question and the reasons why you
              allege that the invoice is incorrect to be eligible to claim an
              adjustment or credit, failure of which the invoice shall be deemed
              to be correct and accepted by you.
            </p>
          </div>
        </li>
        <li>
          <strong>Free Trial Period</strong>
          <div>
            <p>
              REWARDUP may offer you a one-off free of charge trial period (the
              &ldquo;<strong>Trial</strong>
              <strong>Period</strong>&rdquo;), in order to experience the
              features and functionality of our Service. This Trial Period is
              free of charge for the period described on the Websites. When the
              Trial Period is over, you will not be eligible for another free
              trial. If we discover that you have multiple free trial accounts
              with our Websites, we reserve the right to restrict or terminate
              any one or more of such accounts of our choice, in our sole
              discretion. Unless we determine otherwise in our absolute
              discretion, such termination will involve the deletion of any or
              all data associated with that account. During Trial Period, you
              are authorised to access and use the Services solely to the extent
              allowed by us. You acknowledge and agree that this Agreement is
              applicable and binding upon you during the Trial Period. To the
              maximum extent permitted by law, we do not make any binding
              representations, warranties, commitments or obligations in
              connection with the Services during any the Trial Period. The
              Services are provided &ldquo;as is&rdquo; and &ldquo;as
              available&rdquo; and, to the maximum extent permitted by law.
            </p>
            <p>
              The Trial Period starts from the creation of a trial account,
              which is indicated by an approval sent by us to the e-mail address
              provided by you. The Trial Period is valid for the period
              described on the Websites unless your use of the Service is
              terminated earlier in accordance with these Terms. As soon as the
              Trial Period expires or is otherwise terminated, if you have not
              upgraded the account to a paid subscription then we may terminate
              the account and your access to the Services at any time in our
              absolute discretion, which may involve the deletion of any or all
              data associated with that account.&nbsp;
            </p>
          </div>
        </li>
        <li>
          <strong>In-Personal Technical Support</strong>
          <div>
            <p>
              REWARDUP retains the right to charge you for travel and
              out-of-pocket expenses incurred by REWARDUP for any technical
              support you may require, provided that any such expense have been
              pre-approved by you in writing and are incurred due to a
              legitimate need to staff the project with REWARDUP personnel at
              your physical location.&nbsp;
            </p>
          </div>
        </li>
        <li>
          <strong>Non-Payment and Suspension</strong>
          <div>
            <p>
              In addition to any other rights granted to REWARDUP herein,
              REWARDUP reserves the right to suspend or terminate the Agreement
              and your access to the Services if your account becomes delinquent
              (falls into arrears) and any amounts due are not paid within ten
              days of your receipt of a notice of non-payment from REWARDUP.
              Notwithstanding the foregoing, REWARDUP reserves the right to
              suspend or terminate the Agreement and your access to the Services
              upon Thirty (30) days&rsquo; notice if your account becomes
              delinquent (falls into arrears) and if any amounts due are not
              paid when due if two (2) or more consecutive payments or three (3)
              or more payments within any twelve-month period are not paid when
              due. REWARDUP reserves the right to impose a reconnection fee in
              the event the Agreement is suspended and you thereafter request
              access to the Services, provided that it shall be a requirement of
              any reconnection that you shall have cured all monetary defaults.
            </p>
          </div>
        </li>
        <li>
          <strong>Termination upon Expiration</strong>
          <div>
            <p>
              The Agreement commences on the Effective Date. The Term will be as
              mutually agreed upon in a Registration Form. Upon the expiration
              of the Term, the Agreement will automatically renew for successive
              renewal terms equal in duration to the Term for a fee determined
              in accordance with Section 6 above. Either party may terminate the
              Agreement effective only upon the expiration of the then current
              Term; provided that written notice of intent to terminate must be
              provided (i) by REWARDUP to you at least thirty (30) days prior to
              the date of termination and (ii) by you to REWARDUP at least
              thirty (30) days prior to the last day of the then current
              Term.&nbsp;
            </p>
            <p>
              In the event that the Agreement is terminated (other than by
              reason of your non-payment), REWARDUP will return to you a file of
              the Customer Data in a machine-readable, non-proprietary format
              suitable for use for on a personal computer within 90 days of
              termination. In the event that REWARDUP terminates the Agreement
              as a result of your breach and you cure such breach within ninety
              (30) days of the termination, REWARDUP will return to you a file
              of the Customer Data within sixty (60) days of your request
              thereafter at its then current rates for such services and after
              settling any outstanding payments. Notwithstanding any of the
              provisions of the Agreement, you agree and acknowledge that, other
              than as specifically set forth therein, REWARDUP has no obligation
              to retain the Customer Data and may delete such Customer Data
              after ninety (90) days of termination.
            </p>
          </div>
        </li>
        <li>
          <strong>Termination Without Cause Without limitation</strong>
          <div>
            <p>
              In the event a Third Party Service Provider terminates its
              agreement with us without notice, REWARDUP, in its sole
              discretion, may terminate this Agreement with you without notice.
              In the event of such termination, you will not be responsible for
              any fees payable following the effective date of such termination
              but will be responsible to pay any fees payable up to the date of
              such termination.
            </p>
          </div>
        </li>
        <li>
          <strong>Termination for Cause Without limitation</strong>
          <div>
            <p>
              Any breach of your payment obligations uncured within 30 days
              receipt of notice thereof or your unauthorized use of the REWARDUP
              Service will be deemed a material breach of the Agreement.
              REWARDUP, in its sole discretion, may terminate the Agreement,
              your password, account and/or use of the Services if you breach or
              otherwise fail to comply with the Agreement and such breach
              remains uncured for more than 30 days following your receipt of
              notice thereof.. You may terminate the Agreement if REWARDUP
              breaches or otherwise fails to comply with the Agreement and such
              breach remains uncured for more than 30 days following
              REWARDUP&rsquo;s receipt of notice thereof. In the event of such
              termination, you will not be responsible for any fees payable
              following the effective date of such termination but will be
              responsible to pay any fees payable up to the date of such
              termination.
            </p>
          </div>
        </li>
        <li>
          <strong>Representations &amp; Warranties</strong>
          <div>
            <p>
              Each party represents and warrants that it has the legal power and
              authority to enter into the Agreement. REWARDUP represents and
              warrants that it will provide the Services in a commercially
              reasonable and workmanlike manner consistent with general industry
              standards. You represent and warrant that you have not falsely
              identified yourself nor provided any false information to gain
              access to the Services and that your billing information is
              correct.
            </p>
          </div>
        </li>
        <li>
          <strong>Moral Rights&nbsp;</strong>
          <div>
            <p>
              You hereby irrevocably waives any moral rights that it may have as
              of the current date or in the future relative to the Developments,
              arising from the Copyright Act (Canada), as amended from time to
              time (or any law replacing such law and having similar effect), or
              any similar law in any applicable jurisdiction, or in Common Law,
              to the extent that these rights may be waived in each
              jurisdiction. These moral rights are waived in favour of REWARDUP,
              its successors and assigns.
            </p>
          </div>
        </li>
        <li>
          <strong>Mutual Indemnification</strong>
          <div>
            <p>
              You shall indemnify, defend and hold REWARDUP, its Affiliates and
              each such party&rsquo;s current, future and former Affiliates,
              officers, directors, employees, attorneys and agents harmless from
              and against any and all claims, costs, damages, losses,
              liabilities and expenses (including reasonable attorneys&rsquo;
              fees and costs on a solicitor/client basis) arising out of or in
              connection with: (i) a claim alleging that REWARDUP&rsquo;s
              authorized use of the Customer Data infringes the rights of, or
              has caused harm to, a third party; (ii) a claim, which if true,
              would constitute a violation by you of your representations and
              warranties; or (iii) a claim arising from the breach by you of the
              Agreement, provided in any such case that REWARDUP (a) gives
              written notice of the claim promptly to you, provided that failure
              to do so will not affect your indemnification obligation except to
              the extent you are prejudiced thereby; (b) gives you sole control
              of the defense and settlement of the claim (provided that you may
              not settle any claim unless you unconditionally release REWARDUP
              of all liability and such settlement does not affect
              REWARDUP&rsquo;s business or Service); (c) provides to you all
              available information and assistance reasonably requested; and (d)
              has not compromised or settled such claim. Notwithstanding the
              foregoing, the provisions of paragraphs (b), (c) and (d) above
              shall not apply in the event that the claim is brought by REWARDUP
              or another indemnity party.REWARDUP shall indemnify, defend and
              hold you and your current, future and former Affiliates, officers,
              directors, employees, attorneys and agents harmless from and
              against any and all claims, costs, damages, losses, liabilities
              and expenses (including reasonable attorneys&rsquo; fees and costs
              on a solicitor/client basis) arising out of or in connection with:
              (i) a claim alleging that the Services, or your authorized use
              thereof, directly infringes or misappropriates the rights
              (including Intellectual Property Rights) of, or has caused harm
              to, a third party; (ii) a claim, which if true, would constitute a
              violation by REWARDUP of its representations or warranties; or
              (iii) a claim arising from breach of the Agreement by REWARDUP;
              provided in any such case that you (a) promptly give written
              notice of the claim to REWARDUP, provided that the failure to do
              so will not affect your indemnification obligation except to the
              extent that you are prejudiced thereby; (b) give REWARDUP sole
              control of the defense and settlement of the claim (provided that
              REWARDUP may not settle any claim unless it unconditionally
              releases you of all liability and such settlement does not cause
              you to act or refrain from acting differently, except with respect
              to the use of the Services); (c) provide to REWARDUP all available
              information and assistance; and (d) have not compromised or
              settled such claim. Notwithstanding the foregoing, the provisions
              of paragraphs (b), (c) and (d) above shall not apply in the event
              that the claim is brought by you or another indemnity party.&nbsp;
            </p>
            <p>
              Notwithstanding the foregoing, REWARDUP shall have no
              indemnification obligation, and you shall indemnify REWARDUP
              pursuant to the Agreement for the following:
            </p>
            <ol style={{ listStyle: "lower-roman" }}>
              <li>
                claims arising from any infringement arising from the
                combination of the Services with any of your services, hardware
                or business combination, except where such combination was
                expressly or impliedly authorized by REWARDUP or where such
                claim would have occurred regardless of such processes, and
                REWARDUP shall always be entitled to defend any claim brought
                pursuant to this paragraph.&nbsp;
              </li>
              <li>
                claims arising from end users or any your customers for
                redemption of the monetary value of any rewards from the gift
                cards, digital cards, physical reward card, loyalty cards issued
                by you.
              </li>
            </ol>
          </div>
        </li>
        <li>
          <strong>Disclaimer of Warranties&nbsp;</strong>
          <div>
            <p>
              REWARDUP AND ITS AFFILIATES MAKE NO REPRESENTATION, WARRANTY OR
              GUARANTY AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY,
              TRUTH, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICES OR
              ANY CONTENT. REWARDUP AND ITS AFFILIATES DO NOT REPRESENT OR
              WARRANT THAT (A) THE USE OF THE SERVICES WILL BE SECURE, TIMELY,
              UNINTERRUPTED OR ERROR-FREE, OR OPERATE IN COMBINATION WITH ANY
              OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA, (B) THE SERVICES WILL
              MEET YOUR REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED DATA WILL
              BE ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY SERVICES,
              SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED, LICENSED OR
              OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR REQUIREMENTS
              OR EXPECTATIONS, (E) ERRORS OR DEFECTS WILL BE CORRECTED, OR (F)
              THE SERVICES OR THE SERVER(S) THAT MAKE THE SERVICES AVAILABLE ARE
              FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE SERVICES AND ALL
              CONTENT ARE PROVIDED TO YOU STRICTLY ON AN &ldquo;AS IS&rdquo;
              BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER
              EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT
              LIMITATION, ANY IMPLIED WARRANTY OF YOUABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE
              HEREBY DISCLAIMED BY REWARDUP AND ITS AFFILIATES TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW.
            </p>
          </div>
        </li>
        <li>
          <strong>Limitation of Liability&nbsp;</strong>
          <div>
            <p>
              IN NO EVENT SHALL EITHER PARTY&rsquo;S AGGREGATE LIABILITY EXCEED
              THE AMOUNTS ACTUALLY PAID BY YOU IN THE THREE (3) MONTH PERIOD
              IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM. IN NO
              EVENT SHALL EITHER PARTY AND/OR ITS AFFILIATES BE LIABLE TO ANYONE
              FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL,
              CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS
              OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE)
              ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THE SERVICES,
              INCLUDING, BUT NOT LIMITED TO, THE USE OR INABILITY TO USE THE
              SERVICES, ANY CONTENT OBTAINED FROM OR THROUGH THE SERVICES, OR
              ANY INTERRUPTION, INACCURACY, ERROR OR OMISSION, REGARDLESS OF
              CAUSE IN THE CONTENT, EVEN IF THE PARTY FROM WHICH DAMAGES ARE
              BEING SOUGHT OR SUCH PARTY HAVE BEEN PREVIOUSLY ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES.
            </p>
          </div>
        </li>
        <li>
          <strong>Local Laws and Export Control&nbsp;</strong>
          <div>
            <p>
              The Services and the software and technology used in connection
              therewith may be subject to Canada&rsquo;s export controls
              administered by the Canadian Department of Commerce and other
              Canadian agencies. You acknowledge and agree that the Services
              shall not be used in, and none of the underlying information,
              software, or technology may be transferred or otherwise exported
              or re-exported to, countries as to which Canada maintains an
              embargo (collectively, &ldquo;
              <strong>Embargoed Countries</strong>&rdquo;). The lists of
              Embargoed Countries are subject to change without notice. By using
              the Services, you represent and warrant that you are not located
              in, under the control of, or a national or resident of an
              Embargoed. You agree to comply strictly with all applicable
              Canadian, U.S., Swiss and European Union export laws and assume
              sole responsibility for obtaining licenses to export or re-export
              as may be required. REWARDUP and its Affiliates make no
              representation that the Services is appropriate or available for
              use in locations other than Canada and the United States of
              America. If you use the Services from outside Canada or the United
              States of America, you are solely responsible for compliance with
              all applicable laws, including without limitation export and
              import regulations of other countries. Any diversion of the
              Content contrary to Canadian or the United States law is
              prohibited.
            </p>
          </div>
        </li>
        <li>
          <strong>Notice&nbsp;</strong>
          <div>
            <p>
              REWARDUP may give notice to you by written communication sent by
              registered mail or by e-mail to your address or e- mail address,
              as the case may be, on record in REWARDUP&rsquo;s account
              information. Such notice shall be deemed to have been given upon
              the expiration of 48 hours after mailing (if sent by first class,
              registered mail or nationally-recognized overnight delivery
              service), upon delivery if sent by a nationally recognized
              delivery service, to the address set forth in the Registration
              Form, or upon receipt if sent by e-mail to the e-mail address set
              forth in the Registration Form. You may give notice to REWARDUP at
              any time by letter delivered by nationally recognized overnight
              delivery service or first class postage prepaid mail to REWARDUP
              at 1 Yonge St, #1801, Toronto, ON, M5E 1W7 to the attention of:
              Chief Executive Officer.&nbsp;
            </p>
          </div>
        </li>
        <li>
          <strong>Modification</strong>
          <div>
            <p>
              REWARDUP may amend this Agreement from time to time due to changes
              to the Websites or the Services, to account for developments under
              the law, or for any other commercially reasonable reason. Future
              performance by us of our obligations under this Agreement is
              sufficient consideration for any such amendment. Any amendment
              will only become effective upon notification to you (by email or
              by posting on our Websites) and, if you do not want to agree to
              any such amendment, you should stop using the Websites and the
              Services and contact us to cancel your account.
            </p>
          </div>
        </li>
        <li>
          <strong>Assignment; Change in Control</strong>
          <div>
            <p>
              The Agreement may not be assigned by you without the prior written
              approval of REWARDUP. The Agreement may be assigned by REWARDUP
              without your consent. Any purported assignment in violation of
              this section shall be void. Any actual or proposed change in
              control of you that results or would result in a direct competitor
              of REWARDUP directly or indirectly owning or controlling 50% or
              more of the shares of you (if a corporation) shall be considered
              to be an assignment for the purposes of this section.
            </p>
          </div>
        </li>
        <li>
          <strong>General</strong>
          <div>
            <p>
              This Agreement shall be governed by the laws of the Province of
              Ontario and the laws of Canada applicable therein, without regard
              to the choice or conflicts of law provisions of any jurisdiction,
              and any disputes, actions, claims or causes of action arising out
              of or in connection with this Agreement or the Services shall be
              subject to the exclusive jurisdiction of the courts of the
              province of Ontario. If any provision of this Agreement is held by
              a court of competent jurisdiction to be invalid or unenforceable,
              then such provision(s) shall be construed, as nearly as possible,
              to reflect the intentions of the invalid or unenforceable
              provision(s), with all other provisions remaining in full force
              and effect. No joint venture, partnership, employment, or agency
              relationship exists between you and REWARDUP as a result of this
              agreement or use of the Services. The failure of REWARDUP to
              enforce any right or provision in this Agreement shall not
              constitute a waiver of such right or provision unless acknowledged
              and agreed to by REWARDUP in writing. This Agreement, together
              with any applicable Registration Form, comprises the entire
              agreement between you and REWARDUP and supersedes all prior or
              contemporaneous negotiations, discussions or agreements, whether
              written or oral, between the parties regarding the subject matter
              contained herein. REWARDUP may refer to you in advertising or
              publicity.
            </p>
          </div>
        </li>
        <li>
          <strong>Survival&nbsp;</strong>
          <div>
            <p>
              Sections 4, 12, and 14 of these Terms of Service shall survive any
              expiration or termination of the Agreement. The Agreement and any
              amendments thereto may be executed in counterparts. Each such
              counterpart shall be an original and shall constitute together as
              one and the same document. The parties agree that a photographic
              or facsimile copy of the signature evidencing party&rsquo;s
              execution shall be effective as an original signature and may be
              used in lieu of the original for any purpose.
            </p>
          </div>
        </li>
        <li>
          <strong>Definitions&nbsp;</strong>
          <div>
            <p>
              As used in these Terms of Service and in any Registration Forms
              now or hereafter associated herewith:&nbsp;
            </p>
            <p>
              &ldquo;<strong>Affiliate</strong>&rdquo; means a corporation or
              other entity which is directly or indirectly controlled by you or
              which controls you or is under common control with you;
              corporations or other entities which are Affiliates of the same
              corporation or other entity shall be deemed to be Affiliates of
              each other; &ldquo;
              <strong>Agreement</strong>&rdquo; means the agreement between the
              parties constituted by these Terms of Service and any Registration
              Form.&nbsp;
            </p>
            <p>
              &ldquo;<strong>Content</strong>&rdquo; means the audio and visual
              information, documents, software, Services and services contained
              or made available to you in the course of using the
              Services;&nbsp;
            </p>
            <p>
              &ldquo;<strong>Customer Data</strong>&rdquo; means any data,
              information or material provided or submitted by you to the
              Services in the course of using the Services;&nbsp;
            </p>
            <p>
              &ldquo;<strong>Effective Date</strong>&rdquo; means the earlier of
              either the date this Agreement is accepted by signing digitally or
              in writing, or the date you begin using the Services;&nbsp;
            </p>
            <p>
              &ldquo;<strong>Intellectual Property Rights</strong>&rdquo; means
              unpatented inventions, patent applications, patents, design
              rights, copyrights, trademarks, service marks, trade names, domain
              name rights, mask work rights, know-how and other trade secret
              rights, and all other intellectual property rights, derivatives
              thereof, and forms of protection of a similar nature anywhere in
              the world;&nbsp;
            </p>
            <p>
              &ldquo;<strong>Registration Form(s)</strong>&rdquo; means the form
              evidencing the initial subscription for the Services and any
              subsequent order forms submitted in written form, and agreed to in
              writing by REWARDUP, as may be amended and/or renewed, specifying,
              among other things, the Plan for which you subscribed for, the
              applicable fees, the billing period, and other charges as agreed
              to between the parties. Each such Registration Form shall be
              incorporated into and become a part of these Terms of Service (In
              the event of any conflict between the terms of these Terms of
              Service and the terms of any such Registration Form, the terms of
              the Registration Form shall prevail.);&nbsp;
            </p>
            <p>
              &ldquo;<strong>REWARDUP</strong>&rdquo; means REWARDUP Inc., a
              Canadian corporation, having its principal place of business
              located at 1 Yonge St, #1801, Toronto, ON, M5E 1W7;&nbsp;
            </p>
            <p>
              &ldquo;<strong>Services</strong>&rdquo; means our provision to you
              of the following services:&nbsp;
            </p>
            <ol style={{ listStyle: "lower-alpha" }}>
              <li>
                initial onboarding of your specifications onto the Websites;
              </li>
              <li>
                the implementation and integration of the our widget onto your
                website (if applicable);
              </li>
              <li>
                general advice concerning the development, operation and use of
                our software;
              </li>
              <li>ongoing email support;</li>
              <li>
                identifying and controlling software components and changes
                relating to our software;
              </li>
              <li>monitoring performance of the software; and</li>
              <li>
                any updates, upgrades or subsequent versions of such services
                released by REWARDUP during the Term or any renewal term,
                operated and maintained by REWARDUP, accessible via
                https://www.RewardUp.com or another designated web site or IP
                address, or ancillary online or offline Services and
              </li>
              <li>
                any other services provided to you by REWARDUP, to which you are
                being granted access under this Agreement.
              </li>
            </ol>
            <p>
              &ldquo;<strong>Term</strong>(s)&rdquo; means the period(s) during
              which you are licensed to use the Services pursuant to the
              Registration Form(s);&nbsp;
            </p>
            <p>
              &ldquo;<strong>You</strong>&rdquo; and &ldquo;
              <strong>your</strong>
              &rdquo; mean the signatory to the Registration Form or, if the
              signatory is entering into the Agreement on behalf of a company or
              other legal entity, such company or other legal entity.
            </p>
            <p>
              &ldquo;<strong>Websites</strong>&rdquo; means the site{" "}
              <a href="https://rewardup.com">https://rewardup.com</a>, our
              mobile applications, our plug-ins, and our widget embedded by API
              into software owned and managed by Third Party Service Providers.
            </p>
          </div>
        </li>
      </ol>
    </StylPrivacy>
  );
};

const StylTabs = styled.div`
  margin: 15px 0px;

  a {
    cursor: pointer;
    color: #19191b !important;
    font-size: 1.4rem;
    margin-right: 25px;
    border-bottom: 2px solid transparent;
  }

  .active {
    color: #3b88c3 !important;
    border-bottom: 2px solid #3b88c3;
  }
`;

const PrivacyPolicy = () => {
  const [tab, settab] = useState(1);
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title variant="hero" className="text-center">
                  Terms & Conditions
                </Title>
                <StylTabs>
                  <a
                    className={tab === 1 ? "active" : ""}
                    onClick={() => settab(1)}
                  >
                    Terms for User
                  </a>
                  <a
                    className={tab === 2 ? "active" : ""}
                    onClick={() => settab(2)}
                  >
                    Terms for Merchants
                  </a>
                </StylTabs>
                {tab === 1 ? <UserTerms /> : <MerchantTerms />}
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default PrivacyPolicy;
