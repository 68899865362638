import React, { useEffect, useContext } from 'react'

import GlobalContext from '../../context/GlobalContext'

const PageWrapper = ({ children, headerDark = false, footerDark = false, hasBanner = false }) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if(hasBanner) {
      gContext.setHasBanner(!!hasBanner)
    }
    if (headerDark) {
      gContext.goHeaderDark()
    } else {
      gContext.goHeaderLight()
    }

    if (footerDark) {
      gContext.goFooterDark()
    } else {
      gContext.goFooterLight()
    }
  }, [gContext, headerDark, footerDark, hasBanner])

  return <>{children}</>
}

export default PageWrapper
